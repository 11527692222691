import { CadastroService } from './service/cadastro.service';
import { Storage } from '@ionic/storage';
import { Component } from '@angular/core';
import { Platform, IonNav } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  idAluno: string;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private cadastroService: CadastroService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(9999, async () => {
        document.addEventListener('backbutton', (event) => {
          event.preventDefault();
          event.stopPropagation();
        }, false);
      });
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      this.OneSignalInit();
    });
    this.storage.get('id').then((result) => {
      this.idAluno = result;
      if (this.idAluno !== undefined) {
        this.consultarDados();
      }
    });
  }

  consultarDados() {
    this.cadastroService.consultarDadosAluno(this.idAluno).then((result: any) => {
      this.storage.set('cpf', result.cpf);
      this.storage.set('nome', result.nome);
      this.storage.set('escola', result.nomeEscola);
      this.storage.set('id', result.id);
      this.storage.set('idUsuario', result.idUsuario);
      this.storage.set('matriculados', result.matriculados);
      this.storage.set('foto', result.urlFoto);
      this.storage.set('saldoAcumulado', result.saldoAcumulado);
      this.storage.set('telefone', result.telefone);
      this.storage.set('email', result.email);
      this.storage.set('saldoAtual', result.saldoAtual);
      this.storage.set('enemNumero', result.enemNumero);
      this.storage.set('idEscola', result.idEscola);
      this.storage.set('idPeriodo', result.idPeriodo);
      this.storage.set('idTurma', result.idTurma);
    });
  }

  // Call this function when your app starts
  OneSignalInit() {
  // Uncomment to set OneSignal device logging to VERBOSE  
  // OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  OneSignal.setAppId("c995c202-a2f7-4aab-b7b4-9110d9a06f4e");
  OneSignal.setNotificationOpenedHandler(function(jsonData) {
      // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  });

    // Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      // console.log("User accepted notifications: " + accepted);
  });

}

}
