import { ModalQuestoesErroPage } from './Modals/modal-questoes-erro/modal-questoes-erro.page';
import { ModalResultadoPage } from './Modals/modal-resultado/modal-resultado.page';
import { ModalFinalizarPage } from './Modals/modal-finalizar/modal-finalizar.page';
import { Camera } from '@ionic-native/camera/ngx';
import { PipesModule } from './../shared/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

@NgModule({
  declarations: [AppComponent, ModalFinalizarPage, ModalResultadoPage, ModalQuestoesErroPage],
  entryComponents: [ModalFinalizarPage, ModalResultadoPage, ModalQuestoesErroPage ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({scrollAssist: true}),
    AppRoutingModule,
    HttpClientModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ImageCropperModule,
    Ng2GoogleChartsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.config),
    IonicStorageModule.forRoot({
driverOrder: ['indexeddb', 'sqlite'],
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    Facebook,
    Camera,
    GooglePlus,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
