// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// API: 'http://devapp01.topos.local:9000/api/'
// http://localhost:58026/
export const environment = {
  production: true,
  //EXTERNO
  //API: 'http://35.196.13.54:9000/api/',
  //VM03
  // API: 'http://vm03:9000/api/',
  //VM03 PARA APK
  //API: 'http://devapp01.topos.local:9000/api/',
  //LOCAL
  // API: 'HTTP://localhost:58026/',
  //PROD APRESENTAÇÃO
  API: 'https://homol.adm.beesmart.app.br/api/',
  //USO LOCAL
  // API: 'http://homol.adm.beesmart.app.br/api/',
  // NGROK(MUDAR SEMPRE QUANDO USAR)
  // API: 'https://28b6-170-0-69-90.ngrok-free.app/',


  config: {
    apiKey: 'AIzaSyC682Xekkbi2lcCfjBzi8NvNWpLQ4DrVWg',
    authDomain: 'mobischool-60ca4.firebaseapp.com',
    databaseURL: 'https://mobischool-60ca4.firebaseio.com',
    projectId: 'mobischool-60ca4',
    storageBucket: '',
    messagingSenderId: '998515738081',
    appId: '1:998515738081:web:ea46bf721948625214bea0'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
