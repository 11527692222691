import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-finalizar',
  templateUrl: './modal-finalizar.page.html',
  styleUrls: ['./modal-finalizar.page.scss'],
})
export class ModalFinalizarPage implements OnInit {
  @Input() mensagem: string;
    constructor(
        private modalController: ModalController
    ) { }
    ngOnInit() {
    }

    confirmar(): void {
      this.modalController.dismiss(true);
    }

    dismiss() {
      this.modalController.dismiss({
        dismissed: true
      });
    }
}
