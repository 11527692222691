import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})

export class StripHTML implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(style) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }

  // transform(value, args) {
  //   let striped = value.replace(/(<([^>]+)>)/g, "");

  //   if (args != null) {
  //     if (args.split != null) {
  //       striped = striped.split(args.split);
  //       if (args.index != null) {
  //         striped = striped[args.index];
  //       }
  //     }
  //   }

  //   return striped;
  // }

  
}