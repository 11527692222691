import { Storage } from '@ionic/storage';
import { Aluno } from './../perfil/aluno';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CadastroService {
  headers: any;

  constructor(private http: HttpClient, private storage: Storage) {
    this.storage.get('token').then((token) => {
      this.headers = new HttpHeaders().set('token', token );
    });
  }

  Cadastrar(aluno) {
    return new Promise((resolve, reject) => {
      const url = environment.API + 'aluno/inserir';
      this.http.post(url, aluno).subscribe(
        (result: any) => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }


  buscarNomeAluno(aluno) {
    return new Promise((resolve, reject) => {
      const url = environment.API + 'aluno/buscarnome';
      this.http.post(url, aluno).subscribe(
        (result: any) => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  consultarDadosAluno(id: string) {
    const url = environment.API + 'aluno/consultardados/' + `${id}`;
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        this.headers = new HttpHeaders().set('token', token );
        this.http.get(url , { headers: this.headers}).subscribe(
          (result: any) => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
      });
    });
  }

  Editar(aluno) {
    const url = environment.API + 'aluno/editar';
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        this.http.put(url, aluno, { headers: this.headers}).subscribe(
          (result: any) => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
      });
    });
  }

  validarDados(aluno) {
    return new Promise((resolve, reject) => {
      const url = environment.API + 'aluno/validar';
      this.http.post(url, aluno).subscribe(
        (result: any) => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

}
