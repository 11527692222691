import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, ExtraOptions } from '@angular/router';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'master', loadChildren: './master/master.module#MasterPageModule' },
  { path: 'menu-dificuldade', loadChildren: './menu-dificuldade/menu-dificuldade.module#MenuDificuldadePageModule' },
  { path: 'cadastro', loadChildren: './cadastro/cadastro.module#CadastroPageModule' },
  { path: 'questionario', loadChildren: './questionario/questionario.module#QuestionarioPageModule' },
  { path: 'pessoal', loadChildren: './cadastro/pessoal/pessoal.module#PessoalPageModule' },
  { path: 'academico', loadChildren: './cadastro/academico/academico.module#AcademicoPageModule' },
  { path: 'esqueci-senha', loadChildren: './esqueci-senha/esqueci-senha.module#EsqueciSenhaPageModule' },
  { path: 'modal-finalizar', loadChildren: './Modals/modal-finalizar/modal-finalizar.module#ModalFinalizarPageModule' },
  { path: 'modal-resultado', loadChildren: './Modals/modal-resultado/modal-resultado.module#ModalResultadoPageModule' },
  { path: 'modal-questoes-erro', loadChildren: './Modals/modal-questoes-erro/modal-questoes-erro.module#ModalQuestoesErroPageModule' },
  { path: 'perfil', loadChildren: './perfil/perfil.module#PerfilPageModule' },
  { path: 'ranking', loadChildren: './ranking/ranking.module#RankingPageModule' },
  { path: 'meu-desempenho', loadChildren: './meu-desempenho/meu-desempenho.module#MeuDesempenhoPageModule' },
  { path: 'gabarito', loadChildren: './gabarito/gabarito.module#GabaritoPageModule' },
  { path: 'perfil-academico', loadChildren: './perfil/perfil-academico/perfil-academico.module#PerfilAcademicoPageModule' },
  { path: 'editar-senha', loadChildren: './perfil/editar-senha/editar-senha.module#EditarSenhaPageModule' },
  { path: 'historico', loadChildren: './historico/historico.module#HistoricoPageModule' },
  { path: 'assunto', loadChildren: './assunto/assunto.module#AssuntoPageModule' },
];

const config: ExtraOptions = {
  onSameUrlNavigation: 'reload'
};
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , onSameUrlNavigation: 'reload' , 
     scrollPositionRestoration: 'enabled'} ),
     Ng2GoogleChartsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
