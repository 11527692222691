import { NgModule } from '@angular/core';
import { StripHTML } from './StripHTML';

@NgModule({
  declarations: [
    StripHTML,
  ],
  imports: [

  ],
  exports: [
    StripHTML
  ]
})
export class PipesModule { }